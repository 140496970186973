<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table-with-query-object :table="table">
          <div slot="ctrl-button" style="line-height: 32px">
            <el-button
              class="ml40px"
              style="margin-right: 15px"
              type="primary"
              @click="onSearch"
              >查询</el-button
            >
            <el-link type="primary" @click="dialogExport = true">查询数据导出</el-link>
          </div>
        </ayl-table-with-query-object>
      </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{
          item.value
        }}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading"
          >确 定</el-button
        >
        <el-button @click="dialogExport = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "../../../utils/mixins";
export default {
  mixins: [mixins.$exportCheckAll],
  data() {
    const vm = this;
    return {
      dialogExport: false,
      checkList: [],
      checkIndex: [],
      btnLoading: false,
      nav: [
        { name: "出纳结算", path: "" },
        { name: "信息费报表", path: "" },
      ],
      table: {
        api: vm.$api.listMessageOrderReport,
        query: {
          startTime: new Date().setTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1))
          ),
          endTime: new Date().getTime(),
          queryContent: null,
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "dateSection_zl",
            title: "日期",
            date: [],
            modelName: ["startTime", "endTime"],
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "驾驶员姓名",
          },
        ],
        columns: [
          {
            title: "订单号",
            key: "orderId",
          },
          {
            title: "驾驶员",
            key: "driverName",
          },
          {
            title: "提货车号",
            key: "plateNumber",
          },
          {
            title: "运输日期",
            filter: "str2ymd",
            key: "transportTime",
          },
          {
            title: "调度员",
            key: "dispatcher",
          },
          {
            title: "信息费",
            key: "messageFee",
          },
        ],
      },
    };
  },
  methods: {
    async onSearch() {
      await this.$search(this.table);
    },
    // 导出
    async exportExcel() {
      this.btnLoading = true;
      try {
        const url = await this.$api.listMessageExcel({
          params: {
            startTime: this.table.query.startTime,
            endTime: this.table.query.endTime,
            queryContent: this.table.query.queryContent,
          },
          excelKey: "MessageOrderReport",
          indexList: this.checkIndex,
        });
        window.open(url);
        this.dialogExport = false;
      } catch (e) {}
      this.btnLoading = false;
    },
  },

  async mounted() {
    // await this.$search(this.table)
    // 导出
    this.$api
      .GetExcelExportKeyAndColumn({
        excelKey: "MessageOrderReport",
      })
      .then((res) => {
        this.checkList = res.chsList;
        this.checkIndex = this.checkList.map((e) => {
          return e.index;
        });
      });
  },
  // async activated () {
  //   await this.$search(this.table)
  // },
};
</script>

<style lang="sass" scoped>
.content-box /deep/ .el-form-item
    width: 41%
    display: inline-block

/deep/ .el-dialog__header
    border-bottom: 1px solid $grayShallow3

/deep/.el-date-editor.el-input
    width: 160px
</style>
